.qualifications_container{
    max-width: 768px;
}

.qualification_tabs {
    display:flex;
    justify-content:center;
    margin-bottom: var(--mb-2);
}

.qualification_button{
    font-size:var(--h3-font-size);
    font-weight:var(--font-medium);
    margin: 0 var(--mb-1);
    cursor: pointer;
}

.qualification_button:hover{
    color: var(--title-color)    ;
}

.qualification_icon {
    font-size:1.8rem;
    margin-right: var(--mb-0-75);
}
.qualification_active {
    color: var(--title-color-dark)
}
.qualification_sections{
    display:grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}

.qualification_content{
    display:none;
}

.qualification_content_active{
    display:block;
}

.qualification_data{
    display:grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification_title{
    font-size:var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification_subtitle{
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification_calendar{
    font-size: var(--small-font-size);
}

.qualification_rounder{
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}

.qualification_line{
    display: block;
    width: 2px;
    height:100%;
    background-color: var(--text-color);
    transform: translate(5px,-8px);
}

.qualification_padding{
    margin-top:20px;
}

@media screen and (max-width: 580px) {
    .qualification_sections {
        grid-template-columns: 1fr;
    }


    .qualification_line {
        display: block;
        width: 2px;
        height:100%;
        background-color: var(--text-color);
        transform: translate(5px,-8px);
    }

    .qualification_rounder {
        display: inline-block;
        width: 13px;
        height: 13px;
        background-color: var(--text-color);
        border-radius: 50%;
    }

    .qualification_subtitle, .qualification_calendar {
        text-align: center;
    }
}