.home_content {
    grid-template-columns :116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center ;

}


.home_social {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.home_social_icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home_social_icon span {
    visibility: hidden;
    font-size: var(--small-font-size);
    opacity: 0;
    transform: translateY(-10px);
    transition: visibility 0s, opacity 0.3s, transform 0.3s;
    margin: 5px;
}

.home_social_icon:hover span {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.home_social_icon:hover {
    color: var(--title-color-dark);
}

.home_title {
    font-size : var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}
.home_hand {
    width: 24px;
    height: 24px;
    margin-left: 0.4rem;
}
.home_subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}
.home_subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}
.home_description {
    max-width:400px;
    margin-bottom: var(--mb-3);
}
.home_img {
    background: url(../../assets//profile1.jpg);
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 /30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height:300px;
    animation: profile_animate 8s ease-in-out infinite 1s;
}
@keyframes profile_animate {
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%
    }
    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%
    }
    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%
    }
}

.home_container{
    row-gap: 7rem;
}

.home_scroll {
    margin-left: 30rem;
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(3.75rem);
    }
}
.home_scroll-name{
    font-size: var(--small-font-size);
    color: var(--title-color);
    margin: 5px;
}

.home_scroll-arrow{
    color: var(--title-color);
}

.home_scroll:hover{
    color: var(--title-color-dark);
}

.button_container{
    display: grid;
    grid-template-columns: 170px 20px;
    align-items: center;
    column-gap: 100px;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .home_content {
        grid-template-columns: 100px repeat(2,1fr);
        column-gap: 1.25rem;
    }
    /*.home_subtitle {*/
    /*    padding-left:3.75rem;*/
    /*    margin-bottom: var(--mb-1);*/
    /*}*/
    .home_description {
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }
    .home_img{
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 /30%);
     }

    .home_scroll {
        margin-left: 7.5rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .home_content {
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }
    .home_data {
        grid-column: 1/3;
    }
    .home_img{
        order: initial;
        justify-self: initial;
    }
    .home_img{
        width: 200px;
        height: 200px;
        box-shadow: inset 0 0 0 7px rgb(255 255 255 /30%);
    }
    .home_scroll{
        display:none;
    }

}


/* For small devices */
@media screen and (max-width: 350px) {
        .home_img{
            width:180px;
            height:180px;
        }
    }