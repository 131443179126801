.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
    padding: 1.4rem;
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav_logo,
.nav_toggle {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    display: flex;
    align-items: center;
    font-size: var(--normal-font-size);
    transition: .4s;
}

.nav_list {
    display: flex;
    column-gap: 2rem;
}

.nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: .4s;
}

.nav_link .nav_icon {
    font-size: 1.3rem; /* Adjust the size of the icon as needed */
}

.nav_link span {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: visibility 0s, opacity 0.3s, transform 0.3s;
}

.nav_close,
.nav_toggle {
    display: none;
}

/* Active Link */
.active-link,
.nav_link:hover span {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    color: var(--title-color-dark);
}

.nav_link:hover {
    color: var(--title-color-dark)
}
/*=============== BREAKPOINTS ===============*/
/* For medium devices */
@media screen and (max-width: 768px) {
    .header {
        top: initial;
        bottom: 0;
    }

    .nav {
        height: var(--header-height);
    }

    .nav_menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .4s;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }

    /* Display the menu*/
    .show_menu
    {
        bottom: 0;
    }

    .nav_list {

    }

    .nav_icon {
        font-size: 1.2rem;
    }

    .nav_close {
        position:absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }


    .nav_close,
    .nav_toggle {
        cursor: pointer;
        display: block;
    }
}

/* For small devices */
@media screen and (max-width: 480px){

    .nav_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
    .nav_menu{

    }
}