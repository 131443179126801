.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0,0,0,0.1);
}

.footer_container{
    padding:2rem 0 6rem;
    display:grid;
    grid-template-columns: 1fr;
}

.footer_link{
    color:var(--text-color)
}

.footer_title{
    color:var(--title-color);
    text-align:center;
    margin-bottom: var(--mb-2);
}

.footer_link:hover{
    color:var(--title-color);
}
.footer_list{
    display:flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer_social{
    display:flex;
    justify-content: center;
    column-gap: 1rem;
}

.footer_social_icon {
    font-size: 1.25rem;
    color: var(--text-color);
}

.footer_copy{
    text-align: center;
}