.about_icon{
    font-size: 1.25rem;
    color: var(--title-color);
}
.about_container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    column-gap: 4rem;
}
.about_img {
    width: 500px;
    border-radius: 1.5rem;
    justify-self:center;
}

.about_info {
    grid-template-columns:repeat(3,140px);
    gap:0.5rem;
    margin-bottom: var(--mb-2);
}
.about_box {
    background-color: var(--container-color);
    border: 2px solid transparent;
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
    position: relative;
}

.about_box::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2.5px;
    right: -2.5px;
    bottom: -2.5px;
    border-radius: 0.75rem;
    background: linear-gradient(45deg, rgba(0,0,0,0.1), rgba(0,0,0,0) 50%);
    z-index: -1;
}
.about_icon {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}
.about_subtitle{
    font-size: var(--tiny-font-size);
}
.about_description{
    padding: 0.4rem 0 0;
    margin-bottom: var(--mb-2-5);
}
.about_section{
    padding: 6rem 0 2rem;
}

@media screen and (max-width: 768px) {
    .about_container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }
    .about_img{
        width: 220px;
    }
    .about_box{
        padding: 0.75rem 0.5rem;
    }
    .about_data{
        text-align: center;
    }
    .about_info {
        justify-content: center;
    }
    .about_description{
        padding: 0.5rem;
        margin-bottom: 2rem;

    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    body {
        margin: 0 0 var(--header-height) 0;
    }
    .section {
        padding: 2rem 0 4rem;
    }

    .section_subtitle{
        margin-bottom: var(--mb-3)
    }
}

@media screen and (max-width: 576px) {
    .about_info {
        grid-template-columns: repeat(3, 1fr);
    }
    .about_description {
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .about_info {
        grid-template-columns: repeat(2,1fr);
    }
}
