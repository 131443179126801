.skills_info {
    grid-template-columns: repeat(2, 100px);
    align-items: center;
    justify-items: center;
}
.skills_container {
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    column-gap: 4rem;
    justify-items: center;
}

.skills_box {
    background-color: var(--container-color);
    border: 2px solid transparent;
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
    position: relative;
}

.skills_box::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2.5px;
    right: -2.5px;
    bottom: -3px;
    border-radius: 0.75rem;
    background: linear-gradient(45deg, rgba(0,0,0,0.1), rgba(0,0,0,0) 50%);
    z-index: -1;
}

.skills_info_box{
    background-color: var(--container-color);
    text-align: center;
    padding: 1rem 1.25rem;
}

.skills_subtitle{
    font-size: var(--tiny-font-size);
}

.skills_title{
    font-size: var(--small-font-size);
}
.skills_denominator{
    margin-bottom:var(--mb-2) ;
}


/* For medium devices */
@media screen and (max-width: 960px) {
    .skills_container{
        grid-template-columns: repeat(2,1fr);
        column-gap: 2rem;
    }
}

@media screen and (max-width: 594px) {
    .skills_container{
        grid-template-columns: 1fr;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {

}