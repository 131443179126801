.contact_container{
    grid-template-columns: repeat(2,max-content);
    justify-content:center;
    column-gap:6rem;
    padding-bottom: 3rem;
}

.contact_info{
    display:grid;
    row-gap:1rem ;
    grid-template-columns: 300px;
}

.contact_form{
    width:360px
}

.contact_form-div{
    position:relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}
.contact_form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0,0,0,0.1);
    background: var(--container-color);
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact_form-tag{
    position:absolute;
    top:-0.7rem;
    left:1.25rem;
    font-size:var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index:10;
}

.contact_form-area {
    height:11rem;
}

.contact_form-area textarea  {
    resize: none;
}

.contact_title{
    text-align:center;
    font-size: var(--h2-font-size);
    font-weight:var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact_card {
    background-color: var(--container-color);
    border: 2px solid transparent;
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
    position: relative;
}

.contact_card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2.5px;
    right: -2.5px;
    bottom: -3px;
    border-radius: 0.75rem;
    background: linear-gradient(45deg, rgba(0,0,0,0.1), rgba(0,0,0,0) 50%);
    z-index: -1;
}


.contact_card-icon{
    font-size: 2rem;
    color:var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact_card-title{
    font-size: var(--small-font-size);
    font-weight:var(--font-medium) ;
}

.contact_card-data{
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-0-75);
}

.contact_button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display:inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;


}

.contact_button-icon{
    font-size: 1rem;
    transition:0.3s;
}

.contact_button:hover .contact_button-icon{
    transform:translate(0.3rem);
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .contact_container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .contact_form {
        width: 100%;
    }

    .contact_info {
        grid-template-columns: 1fr;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .contact_container {
        grid-template-columns: 1fr;
        padding-bottom: 2rem;
    }

    .contact_form {
        width: 100%;
    }

    .contact_info {
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }
}

/* For small devices */
@media screen and (max-width: 576px) {
    .contact_container {
        grid-template-columns: 1fr;
        padding: 0 1rem;
    }

    .contact_form {
        width: 100%;
    }

    .contact_info {
        grid-template-columns: 1fr;
    }

    .contact_form-div {
        height: auto;
        margin-bottom: var(--mb-1);
    }

    .contact_form-input {
        position: relative; /* Change from absolute to relative */
        padding: 1rem; /* Adjust padding */
    }

    .contact_form-tag {
        top: -1rem;
        left: 1rem;
    }

    .contact_button {
        margin-top: 1rem; /* Add margin for spacing */
    }
}

/* For extra small devices */
@media screen and (max-width: 350px) {
    .contact_title {
        font-size: var(--h3-font-size);
    }

    .contact_card-title,
    .contact_card-data,
    .contact_button {
        font-size: var(--small-font-size);
    }

    .contact_button-icon {
        font-size: 0.875rem;
    }

    .contact_form-input {
        padding: 0.75rem; /* Adjust padding for extra small devices */
    }
}
